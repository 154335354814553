.Page{
    min-width: 1040px;
    width: 1200px;
    text-align: center;
    align-self: center;
    display: inline-block;
    background-color: white;
}
.PlayerPage{
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 92vh;
    min-width: 1000px;
}

.PlayerPage-main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 900px;
    min-width: 600px;
    min-height: 92vh;
    padding-block-start: 10px;
    margin-left: 20px;
}

.PlayerPage-main-title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Video-title{
    font-size: calc(14px + 1vmin);
    font-weight: bold;
}



.Video-discription-line{
    margin-top: 8px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.Video-discription{
    align-self: start;
    margin-top: 8px;
    margin-right: 15px;
    font-size: calc(6px + 1vmin);
    color: dimgray;
}

.PlayerPage-main-body{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.PlayerPage-sider{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: 20px;
    margin-right: 20px;
    min-width: 380px;
    padding-block-start: 10px;
}

.PlayerPage-sider-title{
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    font-size: 20px;
    font-weight: bold;
}

.PlayerPage-video-list-item{
    width: 380px;
    height: 60px;
    border-bottom-width: 1px;
    border-bottom-color: gainsboro;
    border-bottom-style: solid;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Video-title-small{
    font-size: calc(7px + 0.7vmin);
    font-weight: bold;
    max-width: 380px;
    margin-right: 10px;
    word-wrap:break-word; 
    word-break:break-all; 
    overflow: hidden;/*这个参数根据需要来绝对要不要*/
    text-align: left;
}

.PlayerPage-video-list-item-img{
    width: 100px;
    height: 50px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 2px;
}

.PlayerPage-video-list-item-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 10px;
    margin-top: 10px;
    align-self: flex-start;
}

.Log-window{
    display: flex;
    flex-direction: column;
    width: 380px;
    overflow-y: scroll;
    height: 170px;
    font-size: 10px;
}

.Log{
    align-self: start;
    font-size: calc(6px + 1vmin);
    color: dimgray;
    text-align: left;
}