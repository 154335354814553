.Player-container-fullscreen{
    /*position: absolute;*/
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.Player-container{
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.Player-data-positioner{
    z-index: 2;
    position: absolute;
    color: white;
    font-size: 15px;
}

.Player-data-contianer{
    position: relative;
    left: 10px;
    top: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Player-ad-positioner{
    z-index: 1;
    position: absolute;
}

.Player-ad{
    position: relative;
    width: 100%;
    /*background: url(https://funtube-1259626356.cos.ap-shanghai.myqcloud.com/sample-ad-1.jpg);*/
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
}

.Player-ad-close {
    position: absolute;
    right: 0;
    bottom: 86px;
    width: 120px;
    height: 40px;
    background-color: #000000aa;
    border-width: 0;
    color: #fff;
    font-weight: bold;
    outline:none;
}

/*.Player-toolbar-positioner{
    z-index: 3;
    position: relative;
    height: 0px ;
    bottom: 39px;
}*/

.Player-toolbar{
    height: 39px;
    width: 100%;
    /*opacity: 0;*/
    background-color: #00000088;
    transition: opacity, background-color 0.2s;
    -webkit-transition: opacity, background-color 0.2s;
}

/*.Player-container:hover .Player-toolbar{
    opacity: 1;
}

.Player-container-fullscreen .Player-toolbar{
    opacity: 1;
}*/

.SVI-positioner{
    position: relative;
    height: 0px ;
    bottom: 39px;
}
.SVI-container{
    width: 100px;
    height: 39px;
    opacity: 0.1;
}

.Progress-container{
    display: flex;
    flex-direction: column;
    height: 4px;
}

.Progress-content{
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    min-height: 4px;
    width: 100;
}
.Progress-loaded-unplayed{
    flex:1;
    font-size: 1px;
    color: transparent;
    background-color: #cceeff;
    opacity: 0.6;
}

.Progress-unloaded{
    flex:1;
    font-size: 1px;
    color: transparent;
    background-color: #cceeff;
    opacity: 0.3;
}

.ant-slider {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    height: 4px !important;
    margin: 0 0 0 !important;
    padding: 0 0 !important;
    cursor: pointer;
    -ms-touch-action: none;
        touch-action: none;

    position: relative;
    bottom: 4px;
}

.ant-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: transparent;
    border-radius: 2px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.Player-toolbar-content{
    height:35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Player-toolbar-content-left{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Player-toolbar-content-right{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Player-toolbar-timer{
    color:#cceeff;
    font-size: 12px;
}

.Player-toolbar-volume-menu{
    width: 14px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Player-toolbar-volume-slider{
    height: 100px !important;
    width: 4px !important;
    margin: 5px;
}

.Player-toolbar-playback-menu{
    display: flex;
    flex-direction: column;
}

.playback-choice{
    color: #cceeff;
}

.voting-card{
    z-index: 2;
    position: absolute;
    margin-left: 50px;
    margin-top: 120px;
    width: 180px;
    color: white;
    border-radius: 10px;
    padding: 0 0 10px 0;
    background-color: rgba(0, 0, 0, 0.5);
    animation: FadeIn ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.voting-card-fadeOut{
    animation: FadeOut ease 1.5s !important;
    animation-delay: 5s !important;
    animation-iteration-count: 1 !important;
    animation-fill-mode: forwards !important;
}

@keyframes FadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes FadeOut{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

.voting-topic{
    margin-top: 5px;
}

.voting-choices{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: black;
    font-size: small;
}

.voting-button{
    width: 150px;
    margin-top: 5px;
    height: 28px;
    border-radius: 14px;
    overflow: hidden;
}

.voted-shell{
    width: 150px;
    margin-top: 5px;
    height: 28px;
    animation: FadeIn ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.giveLike-options{
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.like-card{
    z-index: 999999;
    position: absolute;
    margin-left: 50px;
    margin-top: 280px;
    width: 100px;
    color: white;
    border-radius: 10px;
    padding: 0 0 10px 0;
    background-color: rgba(0, 0, 0, 0.5);
    animation: FadeIn ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}