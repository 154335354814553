@import '~antd/dist/antd.css';

.App {
  text-align: center;
  /*background-color: #56a2e0;*/
  background-image: url("/img/bg.jpg") !important;
  background-size:cover !important;
}

.App-logo {
  height: calc(1vmin + 12px);
  pointer-events: none;
  margin: 1vmin;
  margin-right: 0;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-header {
  align-self:center;
  background-color:white;
  min-height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 2px 3px #00000022;
  z-index: 20;
}

.App-title{
  font-size: calc(4px + 2vmin);
  line-height: calc(4px + 2vmin);
  font-weight: bold;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.App-footer {
  background-color: #282c34;
  min-height: 30px;
  width: 1200px;
  align-self: center;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-block-start: 10px;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(4px + 1vmin);
  color: white;
}