.IndexPage{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    min-width: 100%;
    
}

.IndexPage-content{
    min-height: calc(92vh - 30px);
    width: 1200px;
    max-width: 100%;
    padding: 10px;
    align-self: center;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
}

.menu-item{
    margin-left: 25px;
    font-size: calc(10px + 0.5vmin);
    color: #6e6e6e;
    font-weight: 550;
}


.menu-item:hover {
    color: #242424;
}

.video-cover{
    width: 90%;
    height: 130px;
    overflow: hidden;
    object-fit: cover;
}