/* 在鼠标悬浮时保持半透明 */
.ant-slider:hover .ant-slider-rail {
    background-color: #e1e1e111;
}

/* 保持拖动按钮的index在广告时间提示之上*/
.ant-slider-handle {
    z-index: 2;
}

.video-react-big-play-button-top-left{
    display: none !important;
}