.IndexPage{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    min-width: 100%;
}


.video-cover{
    width: 100%;
}